import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h2`
  fill: rgb(32, 33, 36);
  stop-color: rgb(32, 33, 36);
  color: rgb(32, 33, 36);
  display: flex;
  font-size: 1.125rem;
  font-weight: 500;
  justify-content: space-between;
  letter-spacing: 0;
  line-height: 1.5rem;
  padding: 20px 0;
`

export const Description = styled.div`
  border: 0;
  box-sizing: border-box;
  color: rgb(95, 99, 104);
  display: -webkit-box;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0142857em;
  line-height: 1.25rem;
  margin: 0;
  max-height: 7.5rem;

  overflow: hidden; /* обрезает текст, который выходит за границы элемента */
  text-overflow: ellipsis; /* добавляет многоточие в конце обрезанного текста */
  width: 100%; /* установите нужную вам ширину */
`
