import {
  Container,
  Content,
  Rating,
  ReviewsText,
  Slider,
  Title,
  WrapperRating,
  ContentSlider,
  WrapperSliders,
  BackSlider,
  MainContainer,
  WrapperTitle,
} from './Ratings.styled'
import { StartsIcon } from './assets'
import { useMemo } from 'react'
import { ArrowForward } from '@shared/assets'

const itemSliders = [
  {
    count: 5,
    slider: '94%',
  },
  {
    count: 4,
    slider: '5%',
  },
  {
    count: 3,
    slider: '0%',
  },
  {
    count: 2,
    slider: '2%',
  },
  {
    count: 1,
    slider: '2%',
  },
]

export const Ratings: React.FC = () => {
  const Sliders = useMemo(
    () =>
      itemSliders.map(({ count, slider }, index) => {
        return (
          <ContentSlider key={`ratings-${index}`}>
            <ReviewsText>{count}</ReviewsText>
            <BackSlider>
              <Slider style={{ width: slider }} />
            </BackSlider>
          </ContentSlider>
        )
      }),
    [itemSliders]
  )

  return (
    <MainContainer>
      <WrapperTitle>
        <Title>Ratings and reviews</Title>
        <ArrowForward />
      </WrapperTitle>
      <Container>
        <WrapperRating>
          <Rating>4.9</Rating>
          <Content>
            <StartsIcon />
            <StartsIcon />
            <StartsIcon />
            <StartsIcon />
            <StartsIcon />
          </Content>
          <ReviewsText>8.5K reviews</ReviewsText>
        </WrapperRating>
        <WrapperSliders>{Sliders}</WrapperSliders>
      </Container>
    </MainContainer>
  )
}
