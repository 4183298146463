import img1 from '@pages/Install/components/Comments/assets/1.png'
import img2 from '@pages/Install/components/Comments/assets/2.png'
import img3 from '@pages/Install/components/Comments/assets/3.png'
import img4 from '@pages/Install/components/Comments/assets/4.png'
import img5 from '@pages/Install/components/Comments/assets/5.png'
import img6 from '@pages/Install/components/Comments/assets/6.png'
import img7 from '@pages/Install/components/Comments/assets/7.png'
import img8 from '@pages/Install/components/Comments/assets/8.png'
import img9 from '@pages/Install/components/Comments/assets/9.png'
import img10 from '@pages/Install/components/Comments/assets/10.png'
import img11 from '@pages/Install/components/Comments/assets/11.png'
import img12 from '@pages/Install/components/Comments/assets/12.png'
import img13 from '@pages/Install/components/Comments/assets/13.png'
import img14 from '@pages/Install/components/Comments/assets/14.png'
import img15 from '@pages/Install/components/Comments/assets/15.png'
import img16 from '@pages/Install/components/Comments/assets/16.png'
import img17 from '@pages/Install/components/Comments/assets/17.png'
import img18 from '@pages/Install/components/Comments/assets/18.png'
import img19 from '@pages/Install/components/Comments/assets/19.png'
import img20 from '@pages/Install/components/Comments/assets/20.png'
import img21 from '@pages/Install/components/Comments/assets/21.png'
import img22 from '@pages/Install/components/Comments/assets/22.png'
import img23 from '@pages/Install/components/Comments/assets/23.png'

export const listComments = [
  {
    icon: img1,
    name: 'অদিত্য মেহতা',
    countStarts: 5,
    date: 'April 8, 2024',
    comment:
      'ওয়াও, এটা সত্যিই অবিশ্বাস্য! একদিনে ২৫,০০০ বাংলাদেশি টাকা জিতে নেওয়া সত্যিই আশ্চর্যজনক! 🎉💰 এই খেলা প্রতিদিন সোফায় বসে খেলে আপনি কতটা অর্জন করতে পারেন তা অবিশ্বাস্য। 🔥',
    peopleFound: 2,
  },
  {
    icon: img2,
    name: 'Sundar',
    countStarts: 16,
    date: 'April 1, 2024',
    comment:
      'Crazy Time অ্যাপ সত্যিই ভালো। আমি এটা এত সহজ মনে করিনি. আপনি এটি ইনস্টল করুন এবং এটি অবিলম্বে কাজ করে। সব মিলিয়ে খুব ভালো। তাই আমি ভেবেছিলাম এটি অন্য কেলেঙ্কারী ছিল। তবে আমি নিশ্চিত ছিলাম যে এমনটি ছিল না।',
    peopleFound: 16,
  },
  {
    icon: img3,
    name: 'রবি পাটেল',
    countStarts: 5,
    date: 'March 29, 2024',
    comment:
      'আমি সত্যিই বিশ্বাস করতে পারিনি যে এটা সত্যি! মাত্র এক সপ্তাহে আমি ৩৭,০০০ বাংলাদেশি টাকা উপার্জন করেছি! 💰💰💰 এটা সত্যিই মনস্তাত্ত্বিক! এটা দেখায় যে অধ্যবসায় এবং একটু ভাগ্যের সাথে, দুর্দান্ত জিনিস ঘটতে পারে।',
    peopleFound: 54,
  },
  {
    icon: img4,
    name: 'रुनवॉल',
    countStarts: 4,
    date: 'March 25, 2024',
    comment:
      'অ্যাপটি দারুণ!!! প্রথমে আমি ভাবিনি যে আমি ক্যাসিনোতে এতটা জিততে পারব, কিন্তু এখন আমি দিনে 10000৳ উপার্জন করি, এবং কখনও কখনও 30 হাজার রুবেল!!!! আমি প্রত্যেকের জন্য Aviator সুপারিশ, অবশ্যই, যদি আপনি অর্থ উপার্জন করতে চান. আমার মতে এটি সেরা স্পোর্টস বেটিং অ্যাপ। আমার পর্যালোচনা থেকে, সবাই টপক্স ডাউনলোড করবেন কিনা তা সিদ্ধান্ত নেবেন, তবে ব্যক্তিগতভাবে আমি এটি সুপারিশ করি!!!!',
    peopleFound: 1,
  },
  {
    icon: img5,
    name: 'एलीटशिन',
    countStarts: 5,
    date: 'March 20, 2024',
    comment:
      'খুব সুন্দর আবেদন. আমি কখনই ভাবিনি যে আমি অনেক টাকা পেতে পারি। আমি সবাইকে এই অ্যাপ্লিকেশনটি ডাউনলোড করার পরামর্শ দিচ্ছি\n',
    peopleFound: 22,
  },
  {
    icon: img6,
    name: 'धर्मात्मा',
    countStarts: 5,
    date: 'March 9, 2024',
    comment:
      'খুব ভাল অ্যাপ্লিকেশন, আমি এমনকি ভাবিনি যে আপনি Aviator-এ টাকা জিততে পারবেন, আমি সবাইকে Aviator অ্যাপ্লিকেশন ইনস্টল করার, বাজি রাখার এবং টাকা জিততে পরামর্শ দিই, কারণ তারা বলে, খুব বেশি অর্থ কখনও আনন্দের সাথে জেতার সুযোগ নেই। , এবং এটা চমৎকার, যারা Aviator-এ যোগ দেন তাদের প্রত্যেকের জন্য শুভকামনা, এই চমৎকার অ্যাপ্লিকেশনটিতে বাজি রাখার জন্য শুভকামনা।',
    peopleFound: 6,
  },
  {
    icon: img7,
    name: 'डिक्सी',
    countStarts: 5,
    date: 'March 1, 2024',
    comment:
      'একটি খুব চমৎকার কোম্পানি TOPX, বড় জয়, দ্রুত অর্থপ্রদান এবং উচ্চ সম্ভাবনা, অনেক স্পোর্টস গেম, খুব সুবিধাজনক এবং ব্যবহার করা সহজ, এমনকি একজন নবাগতও কয়েক ক্লিকে এটি বের করতে পারে এবং আপনি ইতিমধ্যেই অর্থ উপার্জন করছেন। এখন পর্যন্ত, আমি এই অ্যাপ সম্পর্কে শুধুমাত্র মাঝারি মন্তব্য শুনেছি। আমি সবাইকে চেষ্টা করার পরামর্শ দিই',
    peopleFound: 0,
  },
  {
    icon: img8,
    name: 'फर्डिनेंड',
    countStarts: 5,
    date: 'February 26, 2024',
    comment:
      'এভিয়েটর একটি চমৎকার অ্যাপ্লিকেশন, পরিষ্কার ইন্টারফেস, চমৎকার গ্রাফিক্স, কোন ব্যবধান নেই। এমনকি একজন নবজাতক এটি বের করতে পারেন। কিন্তু প্রধান সুবিধা হল আপনি এখানে অতিরিক্ত অর্থ উপার্জন করতে পারেন! এই একেবারে মহান! আমি এই অ্যাপ্লিকেশনটি দীর্ঘদিন ধরে ব্যবহার করছি, এটি আমাকে কখনই হতাশ করেনি, কার্ডে আউটপুট খুব সুবিধাজনক! সাধারণভাবে, আমি প্রত্যেকের কাছে এটি সুপারিশ করি।',
    peopleFound: 34,
  },
  {
    icon: img9,
    name: 'कल्पना',
    countStarts: 5,
    date: 'February 24, 2024',
    comment:
      'Crazy Time, great application for everyone and it has no problems and everything else is fine. Simple, intuitive application. Even a newbie can understand it, so you can download, install and start playing. In this direction, you can not only relax but also earn money by playing this game. Download and install.',
    peopleFound: 9,
  },
  {
    icon: img10,
    name: 'भाप से चलने वाला सोना',
    countStarts: 5,
    date: 'February 22, 2024',
    comment:
      'অ্যাভিয়েটর হল বাজি ধরার জন্য সেরা সেরা অ্যাপ, এটি একেবারেই আশ্চর্যজনক এবং ৳200 থেকে শুরু করে ৳18,000 পর্যন্ত। সহজভাবে এবং সহজে. আমি এই ধরনের বাজি খেলতাম এবং সবসময় হারতাম, কিন্তু Aviator-এ সবকিছুই ন্যায্য, আমি কখনই টাকা হারাই না এবং কোনও অর্থপ্রদানের পদ্ধতিতে টাকা উত্তোলন করি না। এগুলি ছাড়াও, আরও অনেক মানিব্যাগ রয়েছে যাতে আপনি অর্থ স্থানান্তর করতে পারেন। আপনি আমাকে বিশ্বাস না হলে, এটি নিজেই পরীক্ষা করে দেখুন!',
    peopleFound: 1,
  },
  {
    icon: img11,
    name: 'नरकंकाल',
    countStarts: 4,
    date: 'February 18, 2024',
    comment:
      'একটি খুব ভাল TopX অ্যাপ। আমি এটি খুব পছন্দ করি. আমি সবাইকে এই চমৎকার, আশ্চর্যজনক অ্যাপ্লিকেশনটি ডাউনলোড করার পরামর্শ দিই, আপনি অনেক কিছু করতে পারেন এবং এটি খুব ভাল কাজ করে। আমি অত্যন্ত এই অ্যাপ্লিকেশন ডাউনলোড করার সুপারিশ, এটা খুব ভাল সম্পন্ন করা হয়েছে. যেমন একটি চমৎকার অ্যাপ্লিকেশন জন্য ডেভেলপারদের ধন্যবাদ',
    peopleFound: 22,
  },
  {
    icon: img12,
    name: 'पालपुर की जनता',
    countStarts: 4,
    date: 'February 1, 2024',
    comment:
      'TopX একটি মহান এবং সহজ উপার্জন কোম্পানি. আমি অ্যাপ্লিকেশনটি ডাউনলোড করেছি এবং আমি এটির জন্য দুঃখিত নই। আমি আনন্দের সাথে খেলি এবং আমার উপার্জন করা অর্থ একটি ব্যাঙ্ক অ্যাকাউন্টে বা এমনকি ই-ওয়ালেটে তুলে নিই। আমি আপনাকে খেলার চেষ্টা করার পরামর্শ দিচ্ছি, আপনি এটি অনুশোচনা করবেন না। আমি ইতিমধ্যে আমার সমস্ত বন্ধু এবং পরিচিতদের কাছে এটি সুপারিশ করেছি',
    peopleFound: 43,
  },
  {
    icon: img13,
    name: 'शेफ़816',
    countStarts: 5,
    date: 'January 30, 2024',
    comment:
      'হ্যালো বন্ধুরা, আমি আপনাদের সাথে TopX অ্যাপ্লিকেশন সম্পর্কে আমার ধারণা শেয়ার করতে চাই!!! যদিও আমি জুয়া খেলার ব্যক্তি নই, আমি এই অ্যাপ্লিকেশনটি দ্বারা প্রভাবিত হয়েছি, আপনি নিরাপদে বাজি ধরতে পারেন এবং জিততে পারেন এবং তহবিল উত্তোলন করা খুবই সুবিধাজনক। উজ্জ্বল এবং পরিষ্কার গ্রাফিক্স! সাধারণভাবে, সমস্ত নতুন এবং উত্সাহী খেলোয়াড়দের জন্য শুভকামনা',
    peopleFound: 14,
  },
  {
    icon: img14,
    name: 'केवीएनएन',
    countStarts: 4,
    date: 'January 28, 2024',
    comment:
      'সবাইকে হ্যালো, আমি আপনাকে চমৎকার এভিয়েটর ক্যাসিনো অ্যাপ্লিকেশন সম্পর্কে বলতে চাই। ইন্টারফেসটি মহাজাগতিক, কার্ডে সরাসরি এবং সমস্যা ছাড়াই টাকা তুলে নেয়, একটি খুব সৃজনশীল এবং আকর্ষণীয় অ্যাপ্লিকেশন, আমি কেবল সোফায় শুয়ে এটি থেকে প্রচুর অর্থ উত্তোলন করি, আমি সবাইকে ডাউনলোড করার এবং চেষ্টা করার পরামর্শ দিচ্ছি, আপনার ভাগ্য ধরুন। ভাল খেলা',
    peopleFound: 67,
  },
  {
    icon: img15,
    name: 'Nosleep2day',
    countStarts: 5,
    date: 'January 20, 2024',
    comment: 'সুপার ক্যাসিনো অফিস বৈমানিক. আমি সত্যিই এই অ্যাপ্লিকেশন পছন্দ',
    peopleFound: 0,
  },
  {
    icon: img16,
    name: 'अज्ञात',
    countStarts: 5,
    date: 'January 19, 2024',
    comment:
      'টপএক্স ক্রেজি টাইম সম্ভবত আমার ব্যবহৃত অন্যান্য ক্যাসিনোগুলির মধ্যে আমার প্রিয়। ব্যক্তিগতভাবে আমার জন্য, উচ্চ-মানের গ্রাফিক্স এবং একটি ব্যবহারকারী-বান্ধব ইন্টারফেস যা গুরুত্বপূর্ণ, বাজি রাখার এবং আপনার ভাগ্য চেষ্টা করার একটি বাস্তব সুযোগের সাথে মিলিত। নতুনদের জন্য প্রচারমূলক কোড এবং স্বাগত বোনাস রয়েছে। যারা এই ক্ষেত্রে নিজেদের চেষ্টা করতে চান আমি তাদের সুপারিশ. আমার রেটিং 5 তারা. ,',
    peopleFound: 12,
  },
  {
    icon: img17,
    name: 'ब्लैक.बुगाटी',
    countStarts: 5,
    date: 'January 28, 2024',
    comment:
      'শুভ সন্ধ্যা, আমি আপনাকে এভিয়েটর অ্যাপ্লিকেশনটির সাথে পরিচয় করিয়ে দিতে চাই, আমার মতে এটি সেরা অ্যাপ্লিকেশন, যা ভাল কাস্টমাইজযোগ্য, আসক্তিমূলক, আকর্ষণীয়, সহজ, বিশদ, আকর্ষণীয় হতে পারে, তবে এটি আপনার নিজের চেষ্টা করা ভাল, আসুন যান এবং নিজের জন্য দেখুন, আপনি এটি পছন্দ করবেন, আপনি অনেক অবিস্মরণীয় এবং ইতিবাচক আবেগ পাবেন।',
    peopleFound: 23,
  },
  {
    icon: img18,
    name: 'चेरी पाई',
    countStarts: 5,
    date: 'January 12, 2024',
    comment:
      'আশ্চর্যজনক গেম, আমি সত্যিই এটি পছন্দ করেছি, অনেক সুবিধা, অনেক ইতিবাচক আবেগ, অনেক অতিরিক্ত ফাংশন, যদি কিছু ত্রুটি থাকে যেগুলি নিয়ে কাজ করা দরকার, তবে সামগ্রিকভাবে আমি অ্যাপ্লিকেশন এবং এভিয়েটর সত্যটি নিয়ে সন্তুষ্ট একটি দুর্দান্ত কাজ করেছে এভিয়েটর এটি আমার জন্য পুরোপুরি উপযুক্ত, আমি সত্যিই সবাইকে পছন্দ করেছি এবং মেজাজ ভাল ছিল',
    peopleFound: 19,
  },
  {
    icon: img19,
    name: 'আদ্যা মেহতা',
    countStarts: 5,
    date: 'January 11, 2024',
    comment:
      'এক সপ্তাহ পরে আমার ব্যালেন্স দেখে আমার চোয়াল পড়ে গিয়েছিল – ৩৭,০০০ বাংলাদেশি টাকা! 💸💸💸 আমি এখনো আমার ভাগ্যে বিশ্বাস করতে পারি না!',
    peopleFound: 43,
  },
  {
    icon: img20,
    name: 'অর্জুন সিংহ',
    countStarts: 5,
    date: 'January 3, 2024',
    comment: 'এই অ্যাপ আমার সমস্ত সমস্যা সমাধান করেছে! আমি নিজেই এটা কিনেছি।',
    peopleFound: 26,
  },
  {
    icon: img21,
    name: 'অর্জুন সিংহ',
    countStarts: 5,
    date: 'January 3, 2024',
    comment: 'এই অ্যাপ আমার সমস্ত সমস্যা সমাধান করেছে! আমি নিজেই এটা কিনেছি।',
    peopleFound: 23,
  },
  {
    icon: img22,
    name: 'আদ্যা মেহতা',
    countStarts: 5,
    date: 'January 3, 2024',
    comment:
      'এই অ্যাপ হলো গেম-চেঞ্জার! 🎮💥 যেকোনো সময়, যেকোনো স্থানে খেলার সুবিধা, সঙ্গে বড় জয়ের উত্তেজনা, এই অ্যাপটি আমার ক্যাসিনো বিনোদনের শীর্ষ পছন্দ.',
    peopleFound: 19,
  },
  {
    icon: img23,
    name: 'জয়ন রাহুল',
    countStarts: 5,
    date: 'December 27, 2023',
    comment:
      'এই অ্যাপটি একেবারে চমৎকার! বিভিন্ন ধরণের গেমের একটি বিশাল জগত যাতে কখনই আপনি বিরক্ত হবেন না।',
    peopleFound: 67,
  },
]
