import {
  Container,
  ContentAbout,
  ContentUpdate,
  DateUpdate,
  DescriptionAbout,
  ItemImage,
  ListImage,
  TextData,
  TitleAbout,
  TitleUpdate,
  WrapperData,
  WrapperTitle,
} from './ContentInfo.styled'
import img1 from './assets/img-1.jpg'
import img2 from './assets/img-2.jpg'
import img3 from './assets/img-3.jpg'
import img4 from './assets/img-4.jpg'
import img5 from './assets/img-5.jpg'
import { ArrowForward, ArrowDown } from '@shared/assets'
import { useState } from 'react'

export const ContentInfo: React.FC = () => {
  const [isDescription, setIsDescription] = useState(false)

  return (
    <Container>
      <ListImage>
        <ItemImage src={img1} alt={'img-1'} />
        <ItemImage src={img2} alt={'img-2'} />
        <ItemImage src={img3} alt={'img-3'} />
        <ItemImage src={img4} alt={'img-4'} />
        <ItemImage src={img5} alt={'img-5'} />
      </ListImage>
      <ContentAbout>
        <WrapperTitle>
          <TitleAbout>About this game</TitleAbout>
          {isDescription ? (
            <ArrowDown onClick={() => setIsDescription(prev => !prev)} />
          ) : (
            <ArrowForward onClick={() => setIsDescription(prev => !prev)} />
          )}
        </WrapperTitle>
        <DescriptionAbout $isDescription={isDescription}>
          ✅ Crazy Time TOPX ক্যাসিনো অফিসিয়াল অ্যাপ
          <br />
          🔥 300 ৳ জমা করুন এবং 48,000 ৳ পান!
          <br />
          🚀ইনস্টল করুন এবং এখনই নিবন্ধন করুন!
          <br />
          🤑 এখনই আপনার ভাগ্য চেষ্টা করুন!
          <br />
          💸যেকোন ব্যাঙ্ক থেকে তহবিল তোলার সুবিধা!
          <br />
          <br />
          ক্যাসিনো একটি উত্তেজনাপূর্ণ মোবাইল অ্যাপ্লিকেশন যা ক্যাসিনো বিশ্বের একটি অনন্য প্রলোভন
          প্রদান করে। ক্যাসিনো অযৌক্তিকতা এবং স্ন্যাপিংয়ের সমন্বয় করে, এই অ্যাপটি সমস্ত জুয়া
          প্রেমীদের জন্য উপযুক্ত জায়গা। আপনি যখন অ্যাপের সাথে নিবন্ধন করবেন, আপনি একটি বিশেষ বোনাস
          পাবেন যা আপনাকে একটি উজ্জ্বল নোটে আপনার ক্যাসিনো যাত্রা শুরু করার অনুমতি দেবে। এছাড়াও,
          আপনি বিনামূল্যে রিল স্পিন করতে সক্ষম হবেন এবং উত্তেজনাপূর্ণ স্লট গেমগুলিতে জেতার সম্ভাবনা
          বাড়িয়ে তুলবেন। বিনোদন চালিয়ে যেতে, ডিপোজিট বোনাসগুলি আপনার জন্য অপেক্ষা করছে, যা আপনাকে
          আপনার জয় বাড়াতে এবং আরও বেশি উপভোগ করতে দেয়। আমরা আমাদের খেলোয়াড়দেরও মূল্য দিই, তাই
          আপনার কাছে একটি অনন্য বেটিং সীমা উপলব্ধ রয়েছে। সুতরাং, আপনি কি উত্তেজনা এবং সুযোগের জগতে
          নিজেকে নিমজ্জিত করতে প্রস্তুত? ক্যাসিনো ডাউনলোড করুন এবং বোনাস, ফ্রি স্পিন এবং অবিস্মরণীয়
          জয়ে পূর্ণ একটি উত্তেজনাপূর্ণ ক্যাসিনো যাত্রা শুরু করুন। উজ্জ্বল আবেগ এবং অনন্য সুযোগ
          শুধুমাত্র আমাদের আবেদন আপনার জন্য অপেক্ষা!
        </DescriptionAbout>
        <ContentUpdate>
          <TitleUpdate>Updated on</TitleUpdate>
          <DateUpdate>March 2, 2024</DateUpdate>
        </ContentUpdate>
        <ContentUpdate>
          <WrapperTitle>
            <TitleAbout>Data safety</TitleAbout>
            <ArrowForward />
          </WrapperTitle>
          <DescriptionAbout>
            In this section, developers can specify how applications collect and use data.
          </DescriptionAbout>
          <WrapperData>
            <TextData>No information.</TextData>
          </WrapperData>
        </ContentUpdate>
      </ContentAbout>
    </Container>
  )
}
