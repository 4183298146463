import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  margin: 32px 0 0 0;
`

export const WrapperComment = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
export const IconProfile = styled.img`
  background-size: cover;
  border-radius: 50%;
  height: 32px;
  overflow: hidden;
  width: 32px;
`

export const NameProfile = styled.div`
  fill: #202124;
  stop-color: #202124;
  color: #202124;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0142857143em;
  line-height: 1.25rem;
`

// Content Rating
export const ContainerRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

export const ContainerStarts = styled.div`
  display: flex;
  width: 70px;
`

export const TextDate = styled.div`
  fill: #5f6368;
  stop-color: #5f6368;
  color: #5f6368;
  font-family: Roboto, Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1rem;
  margin-left: 1ch;
`

// Content Rating

// Comment

export const Comment = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.0142857143em;
  line-height: 1.25rem;
  margin-top: 8px;
  overflow-wrap: anywhere;
  color: #5f6368;
`

// Comment

// Found people

export const FoundPeople = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1rem;
  margin-top: 16px;
  color: #5f6368;
`

// Found people

// Action Comment

export const ActionWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`

export const ActionComment = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1rem;
  margin-right: 24px;
  color: #5f6368;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const Button = styled.div`
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 9999px;
  box-sizing: border-box;
  color: #5f6368;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0.25px;
  line-height: 10px;
  display: flex;
  align-items: center;
`

export const TextButton = styled.div`
  align-items: center;
  display: flex;
  margin: 0 12px;
`

// Action Comment

export const SeeAllButton = styled.div`
  color: #01875f;
  font-size: 0.875rem;
  font-weight: 500;
  height: 36px;

  padding: 0 8px;
`
