import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem;
`

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
`

export const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0069d9;
  }
`
