import { Helmet } from 'react-helmet'
import { Button, Subtitle, Title, Wrapper, Image } from '@pages/Cloak/Cloak.styled'

export const Cloak = () => {
  return (
    <>
      <Helmet>
        <title>Реклама машины</title>
      </Helmet>
      <Wrapper>
        <Title>Реклама машины</Title>
        <Subtitle>Купите новую машину с нами</Subtitle>
        <Image
          src='https://d2hucwwplm5rxi.cloudfront.net/wp-content/uploads/2023/09/19065015/Geely-Monjaro-_-Cover-19-9-23.jpg'
          alt='Машина'
        />
        <p>
          Кроссовер Monjaro - премиальная модель Geely по уровню дизайна, материалов и технологий.
          Это новый флагман модельной гаммы Geely в России.
        </p>
        <Button>Купить сейчас</Button>
      </Wrapper>
    </>
  )
}
