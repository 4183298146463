import { Content, Description, Title } from './WhatsNew.styled'

export const WhatsNew = () => {
  return (
    <Content>
      <Title>What's new</Title>
      <Description>Fixed some bugs and improved user experience.</Description>
    </Content>
  )
}
