import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 24px;
  margin-top: 20px;
`

export const WrapperTitle = styled.div`
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Container = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`

export const Title = styled.h2`
  fill: #202124;
  stop-color: #202124;
  color: #202124;
  font-size: 1.125rem;
  font-weight: 500;
  justify-content: space-between;
  letter-spacing: 0;
  line-height: 1.5rem;
`

export const Content = styled.div`
  display: flex;
`

export const WrapperRating = styled.div`
  display: flex;
  flex-direction: column;
`

export const Rating = styled.div`
  fill: #202124;
  stop-color: #202124;
  color: #202124;
  font-size: 3.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 4rem;
`

export const ReviewsText = styled.div`
  fill: #5f6368;
  stop-color: #5f6368;
  color: #5f6368;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1rem;
  margin-top: 0.5rem;
`

// Sliders
export const WrapperSliders = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 16px;
`

export const ContentSlider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 16px;
`

export const BackSlider = styled.div`
  text-size-adjust: 100%;
  fill: #5f6368;
  -webkit-tap-highlight-color: transparent;
  background-color: #e8eaed;
  border: 0;
  border-radius: 9999px;
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  font-size: 100%;
  height: 10px;
  letter-spacing: 0.0142857143em;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  vertical-align: initial;
  visibility: visible;
  width: 100%;
`
export const Slider = styled.div`
  background-color: #01875f;
  border-radius: inherit;
  height: 100%;
`
