import { useMemo } from 'react'
import {
  ActionComment,
  ActionWrapper,
  Button,
  ButtonWrapper,
  Comment,
  ContainerRating,
  ContainerStarts,
  ContainerTitle,
  FoundPeople,
  IconProfile,
  MainContainer,
  NameProfile,
  SeeAllButton,
  TextButton,
  TextDate,
  WrapperComment,
  WrapperTitle,
} from './Comments.styled'
import { StartsIcon, MoreVertIcon } from './assets'
import { listComments } from './listComments'

export const Comments = () => {
  const list = useMemo(
    () =>
      listComments.map(({ icon, peopleFound, name, comment, date }, index) => {
        return (
          <WrapperComment key={`comment-${index}`}>
            <WrapperTitle>
              <ContainerTitle>
                <IconProfile src={icon} alt={'icon-profile'} />
                <NameProfile>{name}</NameProfile>
              </ContainerTitle>
              <MoreVertIcon />
            </WrapperTitle>
            <ContainerRating>
              <ContainerStarts>
                <StartsIcon />
                <StartsIcon />
                <StartsIcon />
                <StartsIcon />
                <StartsIcon />
              </ContainerStarts>
              <TextDate>{date}</TextDate>
            </ContainerRating>
            <Comment>{comment}</Comment>
            <FoundPeople>{peopleFound} people found this review helpful</FoundPeople>
            <ActionWrapper>
              <ActionComment>Did you find this helpful?</ActionComment>
              <ButtonWrapper>
                <Button>
                  <TextButton>Yes</TextButton>
                </Button>
                <Button>
                  <TextButton>No</TextButton>
                </Button>
              </ButtonWrapper>
            </ActionWrapper>
          </WrapperComment>
        )
      }),
    []
  )

  return (
    <MainContainer>
      {list}
      <SeeAllButton>See all reviews</SeeAllButton>
    </MainContainer>
  )
}
