import { useCallback, useState } from 'react'

export const useFetch = <T>(loading?: boolean) => {
  const [isLoading, setIsLoading] = useState(loading ?? false)

  const request = useCallback(async (url: string, options = {}): Promise<T> => {
    try {
      setIsLoading(true)
      const response = await fetch(url, options)

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      return await response.json()
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }, [])

  return { isLoading, request }
}
