import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 72px;
`

export const Divider = styled.div`
  border: 0;
  border-bottom: 1px solid;
  border-color: #e8eaed;
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  font-size: 100%;
  margin: 0 0 36px;
  padding: 36px 0 0;
  width: 100%;
`
