export const ArrowDown = ({ onClick }: { onClick?: () => void }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='20px'
    viewBox='0 -960 960 960'
    width='20px'
    fill='#333'
    onClick={onClick}
  >
    <path d='M480-345 240-585l51-51 189 189 189-189 51 51-240 240Z' />
  </svg>
)
