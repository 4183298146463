export const StarIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='12px'
    viewBox='0 -960 960 960'
    width='12px'
    fill='#333'
  >
    <path d='m243-144 63-266L96-589l276-24 108-251 108 252 276 23-210 179 63 266-237-141-237 141Z' />
  </svg>
)
