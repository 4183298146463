import './global.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Main } from '@app/Main'
import { Cloak } from '@pages/Cloak'

export const StartUp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/white-page' element={<Cloak />} />
      </Routes>
    </BrowserRouter>
  )
}
